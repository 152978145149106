//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";

export default {
  data: function () {
    return {
      user:null,
      password:null,
      msg_error: null,
      show_loader: false,
    }
  },

  created() {
     console.log('create -> [login.js]');
     console.log(this.$store.state.my_user);
    //this.getProductsAction();
    //this.$route.path
  },
  computed: {
    ...mapState(["products"])
  },
  methods: {
    ...mapActions(["getProductsAction", "addToCartAction"]),

    onClickLogin : function() {
      this.show_loader = true;
      // Login
      // {user: this.user, password: this.password}
      // {user: 'admin', password: 'admin4312'}
      
      
      
      
      this.$axios.post('/api/login/', {user: this.user, password: this.password } )
      .then(function (resp) {
        // Login Ok, viene token y lo guardo en  axios
        if ('token' in resp.data) {
          this.msg_error = null;
          this.$store.commit('_my_user',resp.data);
          localStorage.setItem('_token_',resp.data.token);
          localStorage.setItem('_email_',resp.data.email);
          this.$store.state.my_user=resp.data;
          console.log(resp.data);
          this.$router.push("/main/camiones_paneles"); 
          this.$axios.defaults.headers.common['Authorization'] = "Bearer "+resp.data.token;
          this.show_loader = false;
        }
        // Login Error
        else {
          this.msg_error='Error, usuario o clave incorrecta !!';
          this.$store.state.my_user=null;
          this.$axios.defaults.headers.common['Authorization'] = "Bearer x";
          console.log(resp);
          this.show_loader = false;
        }
       
      }.bind(this))
      .catch(function (error) {
        console.log(error);
        this.show_loader = false;
      });

    },

    onEnter: function() {
      console.log('xxx.x.x.x.x.x.');
      this.onClickLogin();
    }
  }
};
